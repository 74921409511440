import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Muki() {

    const grammarData={
        "pageTitle": "～向き",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Phù hợp với…/…Thích hợp với…", "description": "( ～にちょうど合う）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["向きだ・向きの・向きに"],
                "content": [
                    {"text": "N +　向きだ・向きの・向きに"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: Với sự vật, sự việc đó thì phù hợp với N…, thích hợp với N... "},
                    {"text": "※ Ngoài ra 「向き」 còn dùng để chỉ phương hướng( xem ví dụ)"},
                    {"text": "≫ Cấu trúc này dễ nhầm lẫn với 「向け」. "},
                    {"text": "「向け」: Từ hành động có mục đích, cố gắng làm gì đó, tạo ra cái gì đó để dành cho N, hướng đến đối tượng N . "},
                    {"text": "「向き」: Diễn tả sự phù hợp của N với một sự việc, sự vật, vấn đề đã được đưa ra, đã có sẵn.  "}

                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["向き不向き","向きだ","向きの","向き"],
                "examples": [
                    {"japanese":"この仕事は重いものを運ぶので、体の丈夫な人向きだ。","translate":"Công việc này thích hợp cho những người có sức khỏe tốt vì phải vận chuyển những vật nặng."},
                    {"japanese":"このハイキングコースは初心者向きである。","translate":"Cung đường leo núi này phù hợp với người mới."},
                    {"japanese":"この靴はよく運動する人向きではない。","translate":"Đôi giày này không phù hợp cho những người thường xuyên vận động."},
                    {"japanese":"上級クラスと聞いて、日本語会話の授業に参加したが、内容は中級者向きだった。","translate":"Nghe bảo là lớp giao tiếp tiếng Nhật trình độ cao cấp nên đã tham gia, nhưng mà thật ra nội dung học lại phù hợp với trình độ trung cấp."},
                    {"japanese":"どんな仕事でも向き不向きはあるが、とりあえず尽くして頑張ってみたほうがいいと思います。","translate":"Mặc dù mọi công việc đều có sự phù hợp và không phù hợp nhất định, nhưng tôi nghĩ rằng trước hết cứ nên thử cố gắng hết mình xem sao."},
                    {"japanese":"女性の一人旅向きのホームステイを探したが、簡単に見つからなかった。","translate":"Tôi đã tìm kiếm một homestay phù hợp cho phụ nữ đi du lịch một mình nhưng đã không dễ dàng tìm ra được."},
                    {"japanese":"彼女は専業主婦向きだ。","translate":"Cô ấy thích hợp làm nội trợ."},
                    {"japanese":"この服のデザインは斬新だが、仕事向きのものじゃないようだ。","translate":"Thiết kế của bộ quần áo này rất mới mẻ nhưng có vẻ không phải là một lựa chọn phù hợp cho công việc."},
                    {"japanese":"アメリカから輸入されたこのシャツは高級品だから、私のような貧乏人向きではない。","translate":"Chiếc áo này được nhập khẩu từ Mỹ, là một sản phẩm cao cấp nên không phù hợp với người nghèo như tôi."},
                    {"japanese":"通信教育は、自分で時間を作って学ぶ学習方法だから、時間にルーズな人向きではない。","translate":"Học từ xa là phương pháp học bản thân tự thiết lập thời gian nên không phù hợp cho những người không kỷ luật về thời gian."},
                    {"japanese":"この部屋は何向きですか。南向きの部屋です。","translate":"Phòng này ở hướng nào vậy?  Phòng hướng Nam."},
                    {"japanese":"花さんはほんとに前向きな人なのですね。","translate":"Chị Hana thật sự là một người tích cực nhỉ."},
                    {"japanese":"客船の向きを変える。","translate":"Thay đổi hướng của tàu du lịch."}
                ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Muki;
