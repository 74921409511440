import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Tomonaruto() {

    const grammarData={
        "pageTitle": "～ともなると・ともなれば",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Cứ hễ…, Một khi đã …", "description": "( ～Aという高い立場・状況・程度・時になると当然Bという状況になる)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["ともなると・ともなれば"],
                "content": [
                    {"text": "Vる　+　ともなると・ともなれば"},
                    {"text": "N 　+　ともなると・ともなれば"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả nhấn mạnh sự phán đoán, nhận định rằng“ Khi ở trường hợp, vị trí …” thì đương nhiên sẽ…, sẽ trở nên… ."},
                    {"text": "≫ Thường đi với từ chỉ chức vụ, vị trí, thời gian, trường hợp, sự vật, sự việc ở mức độ, tính chất, phạm vi cao hơn so với chức vụ, vị trí, trường hợp, sự việc,… ở quá khứ hay hiện tại. ( Ví dụ：người lớn cao hơn so với trẻ con, sinh viên đại học cao hơn so với học sinh cấp 3, trưởng phòng cao hơn so với nhân viên). "}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ともなると","ともなれば"],
                "examples": [
                    {"japanese":"大学生ともなると、飲み会やサークルなど、友達付き合いにお金がかかる。","translate":"Một khi trở thành sinh viên đại học, việc tham gia các buổi nhậu hoặc các câu lạc bộ đều tốn kém tiền bạc."},
                    {"japanese":"部長ともなれば、部下の面倒も見なければならない。","translate":"Khi trở thành trưởng phòng, đương nhiên phải quan tâm, hỗ trợ cấp dưới."},
                    {"japanese":"社会人ともなると言葉遣いに気を付けなければならない。","translate":"Một khi đã đi làm, cũng phải chú ý đến việc sử dụng từ ngữ."},
                    {"japanese":"都会ともなれば、賑やかだね。","translate":"Cứ là thành phố, thì thật sự là nhộn nhịp nhỉ."},
                    {"japanese":"この辺りには新幹線が通るともなると、観光客が増える。","translate":"Một khi ở vùng này có tuyến Shinkansen đi qua, thì đương nhiên lượng du khách sẽ tăng lên."},
                    {"japanese":"大人ともなればいろいろな悩みが増える。","translate":"Một khi trở thành người lớn, có rất nhiều lo lắng khác nhau."},
                    {"japanese":"主婦ともなると独身時代のように自由な時間が無くなる。","translate":"Một khi trở thành người phụ nữ nội trợ, thời gian tự do giống như thời độc thân sẽ không còn."},
                    {"japanese":"中学生ともなると、ずいぶん大人びてくるものだ。","translate":"Một khi trở thành học sinh trung học, trở nên có vẻ khá là trưởng thành."},
                    {"japanese":"険しい山へ登山に行くともなると、入念な下調べが必要だ。","translate":"Một khi đi leo núi ở những vùng hiểm trở, việc chuẩn bị kỹ lưỡng là rất cần thiết."},
                    {"japanese":"三人の子の親ともなると、自分の時間はない。","translate":"Một khi làm cha mẹ của ba đứa trẻ, không còn thời gian cho bản thân nữa."}
                ]                
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Tomonaruto;
