import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Ageku() {

    const grammarData={
        "pageTitle": "～あげく",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Sau khi… thì/ Sau khi đã… thì / Sau khi… cuối cùng thì… ", "description": "( いろいろ～した後で、最後は残念な結果になった）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["あげく（に）"],
                "content": [
                    {"text": "Vた  +  あげく（に）"},
                    {"text": "N  +   あげく（に）"}

                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: sau khi trăn trở,làm gì một thời gian thì cuối cùng dẫn tới quyết định, kết quả, kết cục không tốt, không mong muốn."},
                    {"text": "≫ Nghĩa tương đương với「末に」 . Nhưng 「末に」dùng được cho cả kết quả tốt và xấu."},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["あげくに","あげく"],
                "examples": [
                    {"japanese":"無理な運動をしたあげくに入院騒ぎを起こしてしまった。","translate":"Sau khi vận động quá sức, đã phải nhập viện."},
                    {"japanese":"2時間も待たされたあげく、結局試合は延期になった。","translate":"Kết quả sau khi chờ đợi suốt 2 tiếng là trận đấu đã bị hoãn."},
                    {"japanese":"しばらく迷ったあげく、断ってしまった。","translate":"Sau khi suy nghĩ một lúc, cuối cùng đã từ chối."},
                    {"japanese":"いろいろ悩んだあげく、会社を辞めることにした。","translate":"Sau khi đã suy nghĩ rất nhiều, cuối cùng tôi đã quyết định nghỉ việc."},
                    {"japanese":"客はいろいろ注文したあげくに、お金を払わないで帰ってしまった。","translate":"Sau khi gọi rất nhiều đồ ăn, khách hàng đã rời đi mà không trả tiền."},
                    {"japanese":"どの服を買うか迷ったあげく、結局何も買わないで帰ってしまった。","translate":"Sau khi phân vân không biết mua bộ đồ nào, cuối cùng đã về nhà mà không mua gì cả."},
                    {"japanese":"彼は遅刻を繰り返したあげく、会社を首になってしまった。","translate":"Sau khi liên tục đi muộn, cuối cùng anh ta đã bị sa thải."},
                    {"japanese":"毎日の残業のあげく、彼女は倒れて入院することになりました。","translate":"Sau những ngày liên tục làm thêm, cô ấy đã ngã gục và phải nhập viện."},
                    {"japanese":"彼はひとにさんざん借金をして迷惑をかけたあげく、消息を絶った。","translate":"Sau khi nợ tiền của nhiều người và gây ra bao rắc rối, cuối cùng anh ta đã lặn mất tăm, không có tin tức."},
                    {"japanese":"彼女は無理してダイエットをしたあげく、とうとう病気になって入院してしまった。","translate":"Sau khi ép bản thân giảm cân quá mức, cuối cùng cô ấy đã mắc bệnh và phải nhập viện."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Ageku;
