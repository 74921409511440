import React from 'react';
import './Homebody.css';
import avatar from './avatar.svg'
import friends from './friends.png'
import process_image from './process.png'
import talk from './talk.jpg'
import { useState } from 'react';
import { useEffect } from 'react';

const Homebody = () => {
  const [eventDate, setEventDate] = useState('');
  const [eventNumber, setEventNumber] = useState(1); // You can adjust this logic based on your event number system

  useEffect(() => {
    const now = new Date();
    const nextSunday = new Date();

    // Calculate the next Sunday
    const daysUntilSunday = (7 - now.getDay()) % 7;
    nextSunday.setDate(now.getDate() + daysUntilSunday);
    nextSunday.setHours(0, 0, 0, 0);

    const formattedDate = `${nextSunday.getMonth() + 1}月${nextSunday.getDate()}日(日)`;
    setEventDate(formattedDate);

    // Base date and number
    const baseDate = new Date('2024-08-25'); // Adjust base date as needed
    const baseEventNumber = 24;

    // Calculate the number of weeks since the base date
    const msPerWeek = 7 * 24 * 60 * 60 * 1000;
    const weeksPassed = Math.floor((now - baseDate) / msPerWeek);

    // Update the event number
    setEventNumber(baseEventNumber + weeksPassed + 1);
  }, []);

  const redirectToUrl = () => {
    window.location.href = 'https://us06web.zoom.us/j/84355504154?pwd=V6Je0K05mvHmMjquWbSCbbVNPAU3Ee.1';
  };

  return (
    <main>
      <section class="page1-section">
        <section className='header-section'>
          <h1 className='title'>毎週日曜日ベトナム語日本語オンライン交流会</h1>
          <p className='title'>（{eventNumber}回目）</p>
        </section>
        <figure className='image-container'>
          <img src={avatar} alt="human" />
        </figure>
        <section className='zoom-url'>
          <section className='date-time-container'>
              <p className='date-time'>{eventDate}</p>
              <div className='timezone'>
                <p>21:00~22:30 (日本)</p>
                <p>19:00~20:30 (ベトナム)</p>
              </div>
          </section>
          <button className='button' onClick={redirectToUrl}>参加</button>
        </section>

      </section>

     <br></br>

      <section className='page2-section'>

        <article class="content-container1">
          <div className='paticipant'>
            <h2>対象</h2>
            <ul>
              <li>日本とベトナムの生活や文化に興味がある方</li>
              <li>新しい友達を作りたい方</li>
              <li>日本語で会話したいベトナムの方</li>
              <li>ベトナム語で会話したい日本人の方</li>
            </ul>
          </div>
          <figure>
            <img src={friends} alt="対象" />
          </figure>
        </article>

        <article class="content-container2">
          <figure className='icon'>
            <img src={process_image} alt="参加手順"/>
          </figure>
          {/* <h4 className='icon'>ホストがメインルームから各ブレイクアウトルームに移動させます。</h4> */}
          <div className='process'>
            <h2>参加手順</h2>
            <ol>
                <li><strong>「参加」</strong>ボタンを押してZoomに参加してください</li>
                <li>Zoomに参加後、最初にメインルームに入室します</li>
                <li>ホストが各メンバーをメインルームからそれぞれのブレイクアウトルームに移動させます</li>
                <li>
                  各ブレイクアウトルームは30分ごとに切り替わります
                  <ul class="session-list">
                    <li>第1セッション: 21:00〜21:30</li>
                    <li>第2セッション: 21:30〜22:00</li>
                    <li>第3セッション: 22:00〜22:30</li>
                  </ul>
                </li>
              </ol>
          </div>
        </article>

        <article class="content-container1">
          <div className='paticipant'>
            <h2>内容</h2>
            <ul>
              <li>話題は自由です</li>
              <li>参加費は無料です</li>
              <li>日本人とベトナム人がペアを組んで、優先的にクラスを設置します</li>
              <li>カメラをオンにしてご参加ください</li>
            </ul>
          </div>
          <figure>
            <img src={talk} alt="対象" />
          </figure>
        </article>
      </section>

      <footer className="footer">
      <p><a href="https://www.facebook.com/groups/tomodachisan" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook"></i> グループ参加</a></p>
      </footer>
    </main>


  );
};

export default Homebody;