import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Yonimonai() {

    const grammarData={
        "pageTitle": "~ようにも…ない ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Dù có muốn cũng không được…/Dù có muốn cũng không thể… ", "description": "( しようと思っても、事情があってできない）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["にも…ない"],
                "content": [
                    {"text": "Vよう + にも…ない"}

                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: Dù muốn cũng không thể vì có lý do, nguyên cớ nào đó. "},
                    {"text": "≫ Vế sau thường dùng thể khả năng."},
                    {"text": "≫ Động từ trước và sau 「にも」thường là một."},
                    {"text": "≫ Thể hiện cảm xúc, suy nghĩ tiêu cực, sự bất lực hoặc biện minh, cái cớ cho sự việc, vấn đề. "}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["にも","ない","なかった"],
                "examples": [
                    {"japanese":"風が強すぎて走ろうにも走れない。","translate":"Gió thổi quá mạnh, dù muốn chạy cũng không thể chạy được."},
                    {"japanese":"体を壊したせいで、働こうにも働けない。","translate":"Vì sức khỏe đã suy giảm nên dù muốn cũng không thể làm việc được."},
                    {"japanese":"彼女は物を言おうにも息がつけません。","translate":"Cô ấy không còn hơi sức nào mà nói nữa."},
                    {"japanese":"緊張して質問しようにも声が出なかった。","translate":"Vì quá lo lắng nên dù muốn hỏi cũng không mở miệng ra được."},
                    {"japanese":"結婚しようにも相手がいない。","translate":"Dù muốn cũng không có đối tượng để kết hôn."},
                    {"japanese":"時間がないので友達と遊びに行こうにも行けない。","translate":"Vì không có thời gian nên dù có muốn đi chơi với bạn cũng không thể."},
                    {"japanese":"会社を首になっちゃって給料がないので、家賃を払おうにもお金がなかった。","translate":"Bị sa thải khỏi công ty, tiền lương không có nên dù muốn trả tiền nhà cũng chẳng có mà trả."},
                    {"japanese":"彼の連絡先がないため、連絡しようにも連絡できなかった。","translate":"Không thể liên lạc được vì không có thông tin liên lạc của anh ấy."},
                    {"japanese":"早く仕事をしたいが、子供がまだ小さいので働こうにも動けない。","translate":"Dù muốn nhanh chóng đi làm nhưng vì con còn nhỏ nên chưa thể đi làm được."},
                    {"japanese":"こんなにたくさんあるのでは、覚えようにも覚えられない。","translate":"Nhiều thế này dù có muốn nhớ cũng không nhớ được."},
                    {"japanese":"部長が話し続けているせいで、早く帰ろうにも帰れない。","translate":"Vì trưởng phòng cứ tiếp tục nói chuyện nên dù muốn về sớm cũng không thể về được."},
                    {"japanese":"大事な会議の最中だったので、席を外そうにも外せなかった。","translate":"Vì đang trong cuộc họp quan trọng nên dù muốn ra ngoài cũng không thể."},
                    {"japanese":"大先輩から誘われたから、断ろうにも断れなかった。","translate":"Vì là tiền bối rủ nên dù có muốn từ chối cũng không thể từ chối được."},
                    {"japanese":"熱があって頭痛もし、今朝、起きようにも起きられなかった。","translate":"Sáng nay đau đầu và sốt cao nên dù muốn dậy cũng không dậy nổi."},
                    {"japanese":"新聞の募集広告に応募しようにも、切手代どころか封筒を買う金もないという始末です。","translate":"Dù muốn ứng tuyển công việc từ quảng cáo tuyển dụng trên báo nhưng đừng nói tiền mua tem cả tiền mua phong bì để nộp đơn ứng tuyển còn không có."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Yonimonai;
