import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Naradeha() {

    const grammarData={
        "pageTitle": "～ならでは・ならではの",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Chỉ có thể là…/Chỉ có…mới… ", "description": "( ～でなければ…できない/~だけに特有の…)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["ならではの","ならでは"],
                "content": [
                    {"text": "N 　　+　ならでは"},
                    {"text": "N1　+　ならではの　+N2"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả sự nhấn mạnh rằng:“ Chỉ có thể là… chứ không phải là điều gì khác”. Nhằm đánh giá cao sự đặc biệt, tính duy nhất của người, sự vật, sự việc, địa điểm được đề cập tới. "},
                    {"text": "≫ Được dùng nhiều trong quảng cáo."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ならではの","ならでは"],
                "examples": [
                    {"japanese":"さすが本場ならではの料理だけあってとてもおいしい。","translate":"Không hổ danh là món ăn chỉ có ở vùng chính gốc, nó thật sự rất ngon."},
                    {"japanese":"彼のプロジェクトには、彼ならではの独創性と情熱が感じられる。","translate":"Dự án của anh ấy thể hiện sự sáng tạo và đam mê đặc biệt của chính anh ấy."},
                    {"japanese":"このジャガイモは北海道ならではだ。","translate":"Khoai tây này đặc trưng chỉ có ở Hokkaido."},
                    {"japanese":"ベトナムでは各民族はその民族ならではの伝統的な服装がある。","translate":"Ở Việt Nam, mỗi dân tộc có trang phục truyền thống riêng biệt của họ."},
                    {"japanese":"この街の雰囲気には、ここならではの魅力がある。","translate":"Bầu không khí của thành phố này mang một sức hấp dẫn đặc biệt chỉ có ở đây."},
                    {"japanese":"このスープは母ならではの料理ですので、どこの店でも同じい味が見つからないよ。","translate":"Món súp này là một món ăn duy nhất của mẹ tôi, bạn sẽ không tìm thấy hương vị giống như vậy ở bất kỳ nhà hàng nào đâu."},
                    {"japanese":"ベトナムならではの観光地と言ったらどこを連想しますか。","translate":"Nếu nhắc đến các điểm du lịch đặc trưng của Việt Nam, bạn nghĩ ngay đến đâu?"},
                    {"japanese":"彼のスタイルには、彼ならではの個性が反映されている。","translate":"Phong cách của anh ấy thể hiện rõ bản sắc riêng độc đáo của anh ấy."},
                    {"japanese":"沖縄ならではの魅力は長くきれいな砂浜と透明度が高い青い海です。","translate":"Sức hấp dẫn đặc trưng chỉ có ở Okinawa là bãi biển dài và sạch cùng biển xanh trong vắt có độ trong suốt cao."},
                    {"japanese":"ベトナムならではの雰囲気を味わいたいならホイアンに行くといいですよ。","translate":"Nếu bạn muốn trải nghiệm bầu không khí đặc trưng của Việt Nam, hãy đến Hội An là tốt nhất."},
                    {"japanese":"この素晴らしい作品は鈴木さんならではのものだ。","translate":"Tác phẩm tuyệt vời này chỉ có thể là của Suzuki."},
                    {"japanese":"全国各地方にその地方ならではの名産がある。","translate":"Ở khắp mọi nơi trên cả nước, mỗi vùng đều có những sản phẩm nổi tiếng của riêng mình."}
                  ] 
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Naradeha;
