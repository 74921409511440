import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Muke() {

    const grammarData={
        "pageTitle": "～向け",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Dành cho…/ Hướng đến…", "description": "( ～が使うために…された/～のために）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["向けだ・向けの・向けに"],
                "content": [
                    {"text": "N +　向けだ・向けの・向けに"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: diễn tả … được tạo ra, sản xuất để dành cho, hướng đến N. "},
                    {"text": "≫ Cấu trúc này dễ nhầm lẫn với 「向き」. "},
                    {"text": "≫ Cấu trúc này dễ nhầm lẫn với 「向け」. "},
                    {"text": "「向け」: Từ hành động có mục đích, cố gắng làm gì đó, tạo ra cái gì đó để dành cho N, hướng đến đối tượng N .  "},
                    {"text": "「向き」: Diễn tả sự phù hợp của N với một sự việc, sự vật, vấn đề đã được đưa ra, đã có sẵn.  "}

                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["向けに","向けの","向け"],
                "examples": [
                    {"japanese":"これは小学生向けに書かれた科学の本です。","translate":"Đây là một quyển sách khoa học được viết dành cho học sinh tiểu học."},
                    {"japanese":"初心者向けの英会話教室に参加した。","translate":"Tôi đã tham gia một lớp học tiếng Anh dành cho người mới bắt đầu."},
                    {"japanese":"この暑い研究書は専門家向けです。","translate":"Cuốn sách nghiên cứu dày cộm này là dành cho các chuyên gia."},
                    {"japanese":"この喫茶店は猫好きの客向けに装飾されています。","translate":"Quán cà phê này được trang trí dành cho khách hàng yêu mèo."},
                    {"japanese":"これが高齢者向けのスマホなので、字が大きくて使いやすいという点に注目されています。","translate":"Đây là dòng điện thoại thông minh dành cho người cao tuổi, vì vậy nó được thiết kế với cỡ chữ lớn và dễ sử dụng."},
                    {"japanese":"これは一人暮らし向けの炊飯器です。","translate":"Đây là một chiếc nồi cơm điện được thiết kế dành cho người sống một mình."},
                    {"japanese":"この街には多くの外国人向けの店があるため、外国人が多いです。","translate":"Do có nhiều cửa hàng dành cho người nước ngoài, nên ở khu phố này có nhiều người nước ngoài."},
                    {"japanese":"最近IT業界に人気があるので初心者向けのITコースがどんどん増えています。","translate":"Gần đây, với sự phổ biến của ngành công nghệ thông tin, các khóa học IT dành cho người mới bắt đầu đang ngày càng tăng."},
                    {"japanese":"この本はコミュニケーションが苦手な人向けです。","translate":"Cuốn sách này dành cho những người yếu kém trong giao tiếp."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Muke;
