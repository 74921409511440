import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Dearedeare() {

    const grammarData={
        "pageTitle": "～であれ～であれ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Cho dù… hay…  ", "description": "( ～N1でもN2でも、どちらの場合であっても)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["であれ"],
                "content": [
                    {"text": "N1　+　であれ　N2　+　であれ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả dù trong bất cứ trường hợp, hoàn cảnh nào( dù N1 hay N2 ) :thì sự việc ở sau cũng không thay đổi. N1 và N2 thường là nhóm danh từ tương phản."},
                    {"text": "≫ Cách nói sang trọng, nhưng có thể dùng trong văn nói."},
                    {"text": "≫「であろうと」 cùng nghĩa nhưng trang trọng hơn. ( ví dụ)"}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["であれ"],
                "examples": [
                    {"japanese":"大人であれ子供であれそれぞれ悩みを持っています。","translate":"Dù là người lớn hay trẻ em, ai cũng có những lo lắng."},
                    {"japanese":"お金持ちであれ貧乏人であれ選挙で投票する権利がある。","translate":"Dù giàu có hay nghèo đói, mọi người đều có quyền bỏ phiếu trong cuộc bầu cử."},
                    {"japanese":"出席であれ欠席であれ、早めに連絡するべきだ。","translate":"Dù có mặt hay vắng mặt, nên liên lạc sớm."},
                    {"japanese":"就職するのであれ進学するのであれ、ちゃんと考えた上で決まったほうがいい。","translate":"Dù đi làm hay tiếp tục học, tốt nhất là quyết định sau khi xem xét cẩn thận."},
                    {"japanese":"理由は何であれ罪を犯すのは間違ったことだ。","translate":"Bất kể là vì lý do gì, phạm tội là sai lầm."},
                    {"japanese":"男であれ女であれ仕事上は責任を果たすべきだ。","translate":"Dù nam hay nữ, mọi người đều phải hoàn thành trách nhiệm trong công việc."},
                    {"japanese":"社長であれ社員であれ、このルールを守らなくてはいけないです。","translate":"Dù là giám đốc hay nhân viên, mọi người đều phải tuân thủ quy tắc này."},
                    {"japanese":"正社員であれアルバイトであれ、客には関係がない話だ。","translate":"Dù là nhân viên chính thức hay làm thêm thì điều này không liên quan đến khách hàng."},
                    {"japanese":"先生であれ学生であれ、この規則には従わないといけない。","translate":"Dù là giáo viên hay học sinh, mọi người đều phải tuân theo quy tắc này."},
                    {"japanese":"未経験者であろうとベテランであろうと、この現場に入るにはこのコーザを受けなければならない。","translate":"Dù là người mới hoặc là người đã dày dặn kinh nghiệm, mọi người đều phải trải qua khóa học này khi tham gia vào công việc."},
                    {"japanese":"たとえ天気がどうであれ、来週の大会は予定通りに行います。","translate":"Bất kể thời tiết ra sao, giải đấu vào tuần tới sẽ tiếp tục như kế hoạch."},
                    {"japanese":"野菜はキャベツであれニガウリであれ好きだ。","translate":"Tôi thích rau cải, dù là cải bắp hay khổ qua."},
                    {"japanese":"地震であれ津波であれ緊急時に冷静になれる人は少ないだろう。","translate":"Vào những tình huống khẩn cấp như động đất hay sóng thần thì rất ít người có thể giữ được bình tĩnh."}
                    ]
            }
        ]
    }

    return (
        <>
        <Detailpage grammarData={grammarData} />
        <Listbutton></Listbutton>
        </>
    );
}

export default Dearedeare;
