import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Narini() {

    const grammarData={
        "pageTitle": "～なりに・なりの",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Theo cách của… / Tương ứng với…/  Trong khả năng.../ Ở lập trường của…/", "description": "( ～のレベル・立場・条件に合った程度で［の］)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["なりに・なりの"],
                "content": [
                    {"text": "V普通・Aい+　なりに・なりの"},
                    {"text": "N・Aな +　なりに・なりの"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả trạng thái tương ứng với (năng lực, khả năng, vai vế, điều kiện,…) để làm, suy nghĩ, cố gắng… điều gì đó. Thường dùng để nói về sự tích cực, cố gắng mặc dù còn … nhưng trong khả năng,.. đã cố gắng…"},
                    {"text": "≫ Ngoài ra còn dùng để chỉ kết quả có được của vế sau được thực hiện dựa theo… ( lời nói, cách nghĩ riêng,…)"},
                    {"text": "≫ Chúng ta sẽ thường bắt cụm　「それなり（に/の）」”tương ứng với, phù hợp với”"},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["それなりに","なりに","なりの"],
                "examples": [
                    {"japanese":"高価ものは高いなりに長持ちする。","translate":"Mặt hàng cao cấp thì tương ứng với sự đắt đỏ của nó thì dùng được lâu."},
                    {"japanese":"私なりに考えて出した結論ですから、あとでどうなっても後悔しません。","translate":"Đó là kết luận mà tôi suy nghĩ ra theo cách của riêng mình, nên dù sau này có ra sao thì tôi cũng không hối hận."},
                    {"japanese":"私は自分なりに美しい。","translate":"Tôi thấy bản thân đẹp theo cách của riêng mình."},
                    {"japanese":"これは下手なりに尽くして頑張った作品です。","translate":"Đây là tác phẩm mà tôi đã cố gắng hết sức dù khả năng còn kém."},
                    {"japanese":"そんなに無理をしなくても、自分なりにやったほうがいいじゃない。","translate":"Không cần phải căng thẳng quá đâu, cứ làm việc trong khả năng của bản thân chẳng phải tốt hơn sao."},
                    {"japanese":"今回の委員会の解散は委員長なりの考えがあって決めたことでしょう。","translate":"Việc giải tán ủy ban lần này có lẽ là quyết định dựa trên quan điểm của chủ tịch hội."},
                    {"japanese":"若い人は若いなりに、将来のことをいろいろ考えています。","translate":"Người trẻ có suy nghĩ về tương lai theo cách riêng của người trẻ tuổi."},
                    {"japanese":"都会より不便だが、田舎には田舎なりに良さがあります。","translate":"Mặc dù không tiện lợi bằng thành phố, nhưng ở quê cũng có những mặt tốt theo theo cách riêng của vùng quê."},
                    {"japanese":"彼はまるであなたの言いなりになっている。","translate":"Anh ta dường như đang làm theo nhưng gì bạn nói."},
                    {"japanese":"子供は子供なりに、気を使っていることがあります。","translate":"Trẻ con cũng có những quan tâm theo cách riêng của trẻ con."},
                    {"japanese":"彼女は自立した女性だからそれなりに扱ったほうがいい。","translate":"Cô ấy là một phụ nữ tự lập, vì vậy hãy đối xử với cô ấy một cách phù hợp."},
                    {"japanese":"彼女は彼女なりに、毎日コツコツと努力しています。","translate":"Cô ấy đang cố gắng mỗi ngày theo trong khả năng của bản thân."},
                    {"japanese":"いやならいやなりの理由があるはずだ。","translate":"Nếu không thích, chắc chắn có lý do không thích của nó."},
                    {"japanese":"彼は老人なのだから、それなりに扱わなければいけない。","translate":"Ông ấy đã người già nên cần phải ứng xử một cách phù hợp."},
                    {"japanese":"確かに、必要となる常識やマナーを教える時などは言って聞かせる必要がありますが、親の言いなりになることには様々なデメリットがあると思います。","translate":"Quả thực, khi cần thiết, Cần phải nói và chỉ bảo về các quy tắc và phép lịch sự, nhưng tôi nghĩ rằng trở thành người luôn làm theo mọi lời nói của bố mẹ sẽ có nhiều mặt tác hại."}
                    ]              
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Narini;
