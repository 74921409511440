import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Dearedeare() {

    const grammarData={
        "pageTitle": "～かれ～かれ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Dù… Hay…  ", "description": "( ～N1でもN2でも、どちらの場合であっても)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["かれ"],
                "content": [
                    {"text": "A1 い　+　かれ　A2 い+　かれ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả dù trong bất cứ trường hợp, hoàn cảnh nào thì sự việc ở sau cũng giống nhau, không thay đổi."},
                    {"text": "≫ Cách nói mang tính quán ngữ. Thường đi với cặp tính từ trái nghĩa nhất định như 多かれ少なかれ ( Dù nhiều hay ít), 遅かれ早かれ ( Dù sớm hay muộn), 善かれ悪かれ ( Dù tốt hay xấu),…"}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["かれ"],
                "examples": [
                    {"japanese":"大人であれ子供であれ多かれ少なかれ悩みを持っています。","translate":"Dù là người lớn hay trẻ em, dù nhiều hay ít thì ai cũng có lo lắng của mình."},
                    {"japanese":"初対面では多かれ少なかれ緊張感があるものだ。","translate":"Ở lần gặp đầu tiên thì ít nhiều sẽ có cảm giác căng thẳng."},
                    {"japanese":"どんなことでも、尽くして頑張れば、遅かれ早かれ芽が出る。","translate":"Dù làm bất cứ điều gì, nếu cố gắng hết mình, thì sớm hay muộn hạt giống thành công sẽ nảy mầm."},
                    {"japanese":"子供は善かれ悪かれ親から影響を受けて育ちます。","translate":"Trẻ em sẽ lớn lên cùng với sự ảnh hưởng tốt hay xấu từ phụ huynh."},
                    {"japanese":"仕事が決まれば遅かれ早かれ、いずれ引っ越すことになる。","translate":"Khi có công việc, sớm muộn gì cũng sẽ phải chuyển đến nơi mới."},
                    {"japanese":"アイドルの行動は善かれ悪しかれ多くの人に影響を与えるだろう。","translate":"Hành động của các idol, dù tốt hoặc xấu, sẽ có ảnh hưởng đến nhiều người."},
                    {"japanese":"こんなにお酒を飲み続けては遅かれ早かれ病気になるだろう。","translate":"Nếu cứ tiếp tục uống rượu nhiều như vậy, sớm muộn sẽ bị bệnh thôi."},
                    {"japanese":"悪いことを犯したら、遅かれ早かれいつかバレるよ。","translate":"Nếu làm điều xấu thì sớm muộn gì cũng sẽ bị phát hiện."},
                    {"japanese":"失敗した経験は多かれ少なかれ誰にでもあるのではないだろうか。","translate":"Bất kỳ ai cũng đã có trải nghiệm thất bại dù ít hay nhiều."},
                    {"japanese":"屋外で働くのは暑かれ寒かれ、大変なものだ。","translate":"Làm việc ngoài trời, nóng hay lạnh, đều vất vả."}
                    ]
            }
        ]
    }

    return (
        <>
        <Detailpage grammarData={grammarData} />
        <Listbutton></Listbutton>
        </>
    );
}

export default Dearedeare;
