import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Mademonai() {

    const grammarData={
        "pageTitle": "～までもない",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Không cần thiết phải…", "description": " ( ～しなくてもいい/わざわざ～する必要がない）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["　までもない"],
                "content": [
                    {"text": "Vる  +　までもない"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: không cần phải làm hoặc làm nó là không cần thiết vì vấn đề đã rõ ràng, sáng tỏ rồi. "},
                    {"text": "≫ Nghĩa tương đương:「には及ばない」,「ことはない」．Nhưng với 「 までもない」 mang hàm ý vì sự thật đã rõ ràng nên không cần thiết phải làm… nữa. Còn với thì 「には及ばない」「ことはない」 còn được dùng với ý nghĩa là trong hoàn cảnh đó thì không cần thiết/không nên làm… , hoặc nếu làm… ngược lại có thể sẽ dẫn đến kết quả không tốt."}

                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["までもない","までもありません"],
                "examples": 
                [
                    {"japanese":"この程度の風なら、わざわざ病院に行くまでもない。","translate":"Chỉ bị cảm nhẹ như này thì không cần thiết phải đi bệnh viện."},
                    {"japanese":"これは簡単な計算問題だから電卓を使うまでもない。","translate":"Đây là bài toán tính toán đơn giản nên không cần thiết phải dùng máy tính cá nhân."},
                    {"japanese":"保険料の負担が大きくなれば国民の不満が爆発することが言うまでもない。","translate":"Nếu gánh nặng về phí bảo hiểm tăng lên thì sự bất mãn của người dân sẽ bùng nổ, đó là điều đương nhiên nên không cần phải nói."},
                    {"japanese":"サボってばかりいるあいつのことだから、どうして休んだのかは聞くまでもない。","translate":"Người toàn trốn học như cậu ta thì không cần hỏi lý do nghỉ học đâu."},
                    {"japanese":"あのレストランはいつ行っても席があるから、先に予約するまでもない。","translate":"Nhà hàng đó luôn có chỗ trống nên không cần thiết phải đặt chỗ trước đâu."},
                    {"japanese":"挨拶の仕方など学生はもう知っているから、教えるまでもない。","translate":"Học sinh thì đã biết cách chào hỏi rồi, nên không cần phải dạy nữa đâu."},
                    {"japanese":"そのことについてなら、今更話し合うまでもありません。","translate":"Về vấn đề đó thì bây giờ không cần phải nói nữa."},
                    {"japanese":"そんなことは調べるまでもなく、ただのうわさだから、みんながすぐに忘れますよ。","translate":"Đó chỉ là tin đồn thôi, mọi người sẽ quên ngay lập tức nên không cần tìm hiểu làm gì đâu."},
                    {"japanese":"二人のうち、どちらに才能があるかなんて、比べるまでもない。","translate":"Không cần thiết phải so sánh giữa hai người đó thì ai có tài năng hơn đâu."},
                    {"japanese":"夫の実家より自分の実家のほうが気が楽なのは言うまでもない。","translate":"Ở nhà mình đương nhiên thoải mái hơn là nhà chồng rồi, đâu cần nói nữa."}
                ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Mademonai;
