import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Atteno() {

    const grammarData={
        "pageTitle": "～あっての",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "B Tồn tại được là nhờ A/ B Có được là nhờ A", "description": " (AがあるからBが成り立つ）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["あっての"],
                "content": [
                    {"text": "N1 ＋ あっての ＋ N2"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: Nhấn mạnh nhờ có A nên B mới được hình thành, mới có B."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["あっての"],
                "examples": [
                    {"japanese":"何事も命あってのことだから、体を大事にしよう。","translate":"Mọi thứ tồn tại vì có sự sống, vì vậy hãy giữ gìn sức khỏe."},
                    {"japanese":"図書館は利用者あっての施設だから、利用者のニーズに応えていなければならない。","translate":"Thư viện tồn tại vì có người sử dụng, vì vậy phải đáp ứng nhu cầu của người dùng."},
                    {"japanese":"お客様あっての商売ですから、私どもは何よりお客様を第一に考えています。","translate":"Vì có khách hàng nên mới có buôn bán được, vì vậy chúng tôi luôn đặt khách hàng lên hàng đầu."},
                    {"japanese":"あなたの助けあっての私の命ですから、この恩を一生忘れません。","translate":"Tôi còn sống đến bây giờ là nhờ sự giúp đỡ của bạn, vì vậy suốt đời tôi sẽ không bao giờ quên ơn này."},
                    {"japanese":"愛あっての結婚生活だ。愛がなければ、一緒に暮らす意味がない。","translate":"Cuộc sống hôn nhân tồn tại vì có tình yêu. Nếu không có tình yêu, thì sống cùng nhau không có ý nghĩa gì cả."},
                    {"japanese":"親の応援あっての私の成功です。親の支援がないとここまで行けなかったと思います。","translate":"Sự thành công của tôi tồn tại nhờ sự ủng hộ của cha mẹ. Tôi nghĩ rằng nếu không có sự hỗ trợ của cha mẹ, tôi không thể đạt được điều này."},
                    {"japanese":"自然あっての人類の存在ですので自然の保護をしっかりとしなければならない。","translate":"Sự tồn tại của loài người là nhờ có thiên nhiên, vì vậy chúng ta phải bảo vệ thiên nhiên một cách nghiêm túc."},
                    {"japanese":"メンバーあってのチームだから、強いチームを作るにはメンバーそれぞれの長所を伸ばすことが大切だ。","translate":"Nhờ có các thành viên nên mới có đội tuyển, vì vậy để tạo ra một đội bóng mạnh mẽ, việc phát huy điểm mạnh của các thành viên là điều rất quan trọng."},
                    {"japanese":"お客様あっての仕事だから、言葉遣いに気を付けてください。","translate":"Vì có khách hàng nên mới có công việc, vì vậy hãy chú ý đến cách sử dụng từ ngữ."},
                    {"japanese":"健康あっての人生だ。栄養バランスのよい食事や適度な運動を心掛けよう。","translate":"Cuộc sống là nhờ có sức khỏe. Vì vậy hãy cố gắng chú ý đến chế độ uống cân đối và tập thể dục một cách điều độ."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Atteno;
