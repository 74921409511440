import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Uede() {

    const grammarData={
        "pageTitle": "～上で",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Sau khi… ", "description": "( ～してから/~したという状況で…)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["上での","上で"],
                "content": [
                    {"text": "Vた・Nの + 上で"},
                    {"text": "Vた・Nの + 上での + N"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả thứ tự của hành động, làm A rồi mới làm B."},
                    {"text": "≫ Đây là cách nói cứng."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["うえでの","うえで"],
                "examples": [
                        {"japanese":"親と相談したうえで、留学することにしました。", "translate":"Tôi đã quyết định đi du học sau khi thảo luận với bố mẹ."},
                        {"japanese":"解散はメンバー全員でよく相談したうえでの結論だ。","translate": "Việc giải tán hội là kết luận sau khi thảo luận kỹ lưỡng với tất cả các thành viên."},
                        {"japanese":"これはよく考えたうえでの決断です。","translate": "Đây là một quyết định sau khi suy nghĩ kỹ lưỡng."},
                        {"japanese":"上司と相談したうえで、後日改めてご連絡いたします。","translate": "Sau khi thảo luận với cấp trên, chúng tôi sẽ liên hệ lại sau."},
                        {"japanese":"よく考えたうえで、決めたほうがいいと思います。","translate": "Tôi nghĩ rằng tốt nhất là nên suy nghĩ kỹ lưỡng trước khi quyết định."},
                        {"japanese":"予約の時間を確認したうえで、ご来店ください。","translate": "Xin vui lòng đến cửa hàng sau khi xác nhận giờ hẹn."},
                        {"japanese":"この薬は説明書をよく読んだうえで、召し上がってください。","translate": "Xin vui lòng đọc kỹ hướng dẫn trước khi sử dụng loại thuốc này."},
                        {"japanese":"各条件をよく把握したうえで、契約に署名してください。","translate": "Xin vui lòng ký kết hợp đồng sau khi hiểu rõ mọi điều kiện trong hợp đồng."},
                        {"japanese":"入力した情報をもう一度確認したうえで、「OK」のボタンをクリックしてください。","translate": "Vui lòng kiểm tra lại thông tin đã nhập trước khi nhấn nút \"OK\"."},
                        {"japanese":"アカウントを登録したうえで、試験を申し込むことができます。","translate": "Sau khi đăng ký tài khoản có thể đăng ký thi."}             
                ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Uede;
