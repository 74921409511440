import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Tohaie() {

    const grammarData={
        "pageTitle": "～とはいえ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Tuy/Dù… nhưng…", "description": "( ～けれども/～といっても)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["とはいえ"],
                "content": [
                    {"text": "V普通・Aい　+　とはいえ"},
                    {"text": "N・Aな（だ・である） +　とはいえ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả sự phủ định một phần, mặc dù là vậy nhưng thực tế lại có điểm khác với thông tin đã đưa ra ở vế trước. "},
                    {"text": "≫Văn viết, trang trọng."},
                    {"text": "≫Nghĩa tương đương với: 「と言っても」「けれども」「とはいうものの」."},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["とはいえ"],
                "examples": [
                    {"japanese":"彼女は若いとはいえ、幅広い知識を持っている。","translate":"Cô ấy mặc dù còn trẻ nhưng có kiến thức rộng."},
                    {"japanese":"マイさんは外国人とはいえ、日本人のようににほんごがペラペラ話せます。","translate":"Mặc dù là người nước ngoài, nhưng Mai có thể nói tiếng Nhật lưu loát như người bản xứ."},
                    {"japanese":"ダイエット中とはいえ、出されたご馳走に手を付けないのがよくないと思う。","translate":"Dù đang ăn kiêng, nhưng nếu không động đũa với những món ăn được đưa ra thì thật không tốt."},
                    {"japanese":"11月に入ったとはいえ、冬という気がしない。","translate":"Mặc dù đã vào tháng 11, nhưng không có cảm giác mùa đông."},
                    {"japanese":"この革靴は高価なものとはいえ、丈夫で長持ちします。","translate":"Mặc dù đôi giày da này đắt tiền, nhưng chúng chắc chắn và dùng được lâu dài."},
                    {"japanese":"日本語能力試験一級に合格したとはいえ、まだ流暢には話せない。","translate":"Dù đã đỗ N1, tôi vẫn chưa nói lưu loát được."},
                    {"japanese":"いくつかの間違いがあるとはいえ、スーさんの文章はとても素晴らしい。","translate":"Mặc dù còn một số lỗi, nhưng bài viết của bạn Su rất tuyệt vời."},
                    {"japanese":"留学生とはいえ、日本人も負けない文章だ。","translate":"Mặc dù là du học sinh nhưng, văn phong không thua kém người Nhật."},
                    {"japanese":"わざとしなかったとはいえ、人に迷惑を掛けたら謝るべきだろう。","translate":"Dù không cố ý nhưng nếu làm phiền ai đó thì nên xin lỗi."},
                    {"japanese":"上司とはいえ、そこまでわがままを言われては我慢できない。","translate":"Dù có là cấp trên, tôi cũng không thể chịu được những lời nói ích kỷ như vậy."},
                    {"japanese":"昔より一段と良くなったとはいえ、家事も育児も積極的に行う男性がまだ少ない。","translate":"Mặc dù đã tốt hơn trước khi rất nhiều, nhưng số lượng người đàn ông tham gia tích cực làm việc nhà và chăm sóc con cái vẫn còn ít."}
                    ]            
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Tohaie;
