import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Kotonasini() {

    const grammarData={
        "pageTitle": "～ことなしに",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Mà không…", "description": "( ～しないで)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["ことなしに","なしに・なしには"],
                "content": [
                    {"text": "Vる　　+　ことなしに"},
                    {"text": "N　　+　なしに・なしには"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: việc làm gì đó mà không… Thường nói về việc thông thường lẽ ra phải làm, sẽ làm nhưng lại không thực hiện… "},
                    {"text": "≫  Dùng nhiều trong văn viết. Còn văn nói thì dùng: 「Vないで」."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ことなしに","なしには","なしに"],
                "examples": [
                    {
                      "japanese": "練習することなしにとんな分野でもベテランにならないだろう。",
                      "translate": "→ Không có luyện tập thì không thể trở thành chuyên gia trong bất kỳ lĩnh vực nào cả."
                    },
                    {
                      "japanese": "努力することなしに成功はあり得ない。",
                      "translate": "Không có nỗ lực thì không thể đạt được thành công."
                    },
                    {
                      "japanese": "冒険心なしに、新しい経験を得ることはできない。",
                      "translate": "Không có tinh thần mạo hiểm thì không thể có được trải nghiệm mới."
                    },
                    {
                      "japanese": "勉強ことなしに、知識は身につかない。",
                      "translate": "Không học hành thì kiến thức không thể được tích lũy."
                    },
                    {
                      "japanese": "目標を設定することなしに、成功への道筋は見えてこない。",
                      "translate": "Không đặt mục tiêu thì không thể nhìn thấy con đường dẫn đến thành công."
                    },
                    {
                      "japanese": "ひたすら努力することなしに、人より抜きん出ることはできない。",
                      "translate": "Không nỗ lực hết mình toàn tâm toàn ý thì không thể trở nên vượt trội hơn người khác."
                    },
                    {
                      "japanese": "一歩一歩進んでいくことなしには、大きな目標を達成することはできない。",
                      "translate": "Không kiên trì tiến lên từng bước một thì không thể đạt được mục tiêu lớn."
                    },
                    {
                      "japanese": "あらかじめ準備することなしに、いきなり事を行うべきではない。",
                      "translate": "Không nên thực hiện một việc mà không chuẩn bị trước."
                    },
                    {
                      "japanese": "金額を意識することなしに豪遊する。",
                      "translate": "Ăn chơi xa xỉ mà không ý thức về chuyện tiền bạc."
                    },
                    {
                      "japanese": "挑戦することなしに、成長はあり得ない。",
                      "translate": "Không có tinh thần đương đầu thử thách thì không thể có sự trưởng thành."
                    },
                    {
                      "japanese": "上司の承認なしには、勝手に契約を結ぶことができないだろう。",
                      "translate": "Không thể tự tiện ký kết hợp đồng mà không có sự phê duyệt của cấp trên."
                    },
                    {
                      "japanese": "しっかりと検証することなしに出荷するのは欠陥が発生する可能性が高いだろう。",
                      "translate": "Việc xuất hàng mà không tiến hành kiểm tra kỹ lưỡng thì có khả năng xuất hiện lỗi cao."
                    }
                  ]                  
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </>
        
    );
}

export default Kotonasini;
