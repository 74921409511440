import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Bakoso() {

    const grammarData={
        "pageTitle": "～ばこそ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Chính vì…", "description": "( 他のことではない～という理由で)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["こそ","い","な"],
                "content": [
                    {"text": "Vば・Aいければ　　+　　こそ"},
                    {"text": "N ・Aな 　であれば　+　こそ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả sự nhấn mạnh: “ Chính vì lý do, nguyên nhân này… chứ không phải là điều gì khác”"},
                    {"text": "≫ Thường dùng cho câu có ý nghĩa tốt, tích cực. "},
                    {"text": "≫ Cuối câu thường đi với のだ。"},
                    {"text": "≫ Nghĩa tương đương với 「からこそ」。 nhưng「ばこそ」là cách nói cổ hơn, ít dùng hơn."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ばこそ"],
                "examples": [
                    {"japanese":"人一倍努力をすればこそ、上達も早いというものだ。","translate":"Chính nhờ sự nỗ lực hơn người nên sự tiến bộ mới đạt được nhanh chóng như vậy."},
                    {"japanese":"あなたの努力を認めていればこそ、この仕事を任せるんじゃないか。","translate":"Chính vì công nhận nỗ lực của bạn mà tôi muốn giao việc này cho bạn."},
                    {"japanese":"親が子供の将来を心配すればこそ、厳しく叱ることがあるのです。","translate":"Chính vì lo lắng về tương lai của con cái mà phụ huynh thỉnh thoảng la mắng nghiêm khắc."},
                    {"japanese":"将来貧しい生活を過ごしたくなければこそ、今必死で仕事を頑張っている。","translate":"Chính vì không muốn sống cuộc sống nghèo khó trong tương lai mà tôi đang cố gắng làm việc."},
                    {"japanese":"上司は部下を信用していればこそ、みんながやる気が出て仕事の効率もだんだん上がります。","translate":"Chính vì có sếp tin tưởng mà mọi người có tinh thần làm việc và hiệu suất công việc cũng dần tăng lên."},
                    {"japanese":"グローバル社会であればこそ、世界中で仕事ができるようになりました。","translate":"Chính nhờ xã hội toàn cầu mà bây giờ mọi người có thể làm việc trên khắp thế giới."},
                    {"japanese":"毎日運動していればこそ、体が丈夫で柔らかいのだ。","translate":"Chính vì tập luyện hàng ngày mà cơ thể mạnh mẽ và linh hoạt như vậy."},
                    {"japanese":"おばあさんの応援があればこそ、ここまで行けましたよ。","translate":"Chính vì có sự ủng hộ của bà mà tôi mới có thể tới được ngày hôm nay."},
                    {"japanese":"今回のプロジェクトが成功したのは御社のご協力があればこそだと深く感謝しております。","translate":"Chúng tôi rất biết ơn vì chính nhờ sự hợp tác của quý công ty đã giúp đóng góp vào sự thành công của dự án lần này."}
                ]
                           
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Bakoso;
