import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Saini() {

    const grammarData={
        "pageTitle": "～際［に・は］",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Khi / Trong(trường hợp)…", "description": "( ～の時［に・は］)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["際・際に・際は","に際して・際は"],
                "content": [
                    {"text": "Vる　+　際・際に・際は"},
                    {"text": "Nの  +　際・際に・際は"},
                    {"text": "N　　+　に際して・際は"}

                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Dùng trong các thông báo, văn bản giải thích, hướng dẫn: “Khi, trong( trường hợp), nếu” làm…. thì…"},
                    {"text": "≫ Bằng 「とき」 , nhưng 「際」　dùng cho văn viết, trang trọng. "},
                    {"text": "≫「に際して」: Được dùng cho trường hợp diễn tả sự lưu ý, giải thích, xin lỗi, hoặc thời điểm bắt đầu hoặc diễn ra 1 sự việc."},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["の際には","の際は","際には","際は","に際して"],
                "examples": [
                    {"japanese":"お客様の会社を訪問する際にはスーツを着用しましょう。","translate":"Khi thăm công ty của khách hàng, hãy mặc áo vest."},
                    {"japanese":"非常事態の際はエレベーターを使ってはいけない。","translate":"Trong trường hợp khẩn cấp, xin vui lòng không sử dụng thang máy."},
                    {"japanese":"申込期限は3月末までですが、期限前に満員となった際はご了承ください。","translate":"Hạn chót nộp đơn là cuối tháng ba, nhưng trường hợp số lượng người đăng ký đã đủ trước hạn chót thì sẽ đóng đăng ký, mong quý khách thông cảm."},
                    {"japanese":"お降りの際はお忘れ物のないようにご注意ください。","translate":"Khi xuống xe, vui lòng chú ý đừng để quên đồ."},
                    {"japanese":"離着陸の際には、シートベルトを着用してください。","translate":"Xin vui lòng cài dây an toàn khi cất cánh và hạ cánh."},
                    {"japanese":"地震が発生した際は、冷静になり、管理人の指示に従ってください。","translate":"Trong trường hợp xảy ra động đất, hãy giữ bình tĩnh và tuân theo hướng dẫn của người quản lý."},
                    {"japanese":"ここは重要な書類を保管する部屋です。入室する際にはパスワードの入力が必要です。","translate":"Đây là phòng để lưu trữ tài liệu quan trọng. Cần nhập mật khẩu để vào."},
                    {"japanese":"転入手続きの際に下記の書類を準備して市役所に持ってきてください。","translate":"Khi tiến hành thủ tục nhập cư, vui lòng chuẩn bị các tài liệu dưới đây và mang đến ủy ban thành phố."},
                    {"japanese":"物価高騰支援の申し込みの際は右側の受付でお問い合わせください。","translate":"Về việc nộp đơn xin chính sách hỗ trợ do vật giá leo thang, vui lòng trao đổi tại quầy tiếp nhận phía bên phải."},
                    {"japanese":"商品の返品に際して、送料はお客様のご負担となります。","translate":"Trong trường hợp trả hàng, phí vận chuyển sẽ do khách hàng chịu."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Saini;
