// Import useNavigate hook from react-router-dom
import { useNavigate } from 'react-router-dom';
import './Directionbutton.css'
import { useState, useEffect } from 'react';

// Assume that grammarPoints is an array of path segments corresponding to different grammar points
const grammarPoints = ['uede', 
                        'zunihasumanai',
                        'kotonasini',
                        'dearedeare',
                        'karekare',
                        'deare',
                        'naradeha',
                        'wotosite',
                        'toareba',
                        'tomonaruto',
                        'bakoso',
                        'saini',
                        'naidemonai',
                        'narini',
                        'tohaie',
                        'muki',
                        'muke',
                        'mademonai',
                        'tekaratoiumono',
                        'zukume',
                        'atteno',
                        'ageku', 
                        'yonimonai'
                                        ]; 

// Define your main function component
function ListButton() {
    // Get the navigate function from useNavigate hook
    const navigate = useNavigate();
    const [isBackButtonDisabled, setIsBackButtonDisabled] = useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);


    useEffect(() => {
        const currentGrammarPoint = window.location.pathname.split('/').pop();
        const currentIndex = grammarPoints.indexOf(currentGrammarPoint);
        setIsBackButtonDisabled(currentIndex === 0);
        setIsNextButtonDisabled(currentIndex === grammarPoints.length-1);
      }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    
    // Define navigateGrammar function without passing navigate as a parameter
    const navigateGrammar = (direction) => {
        const currentIndex = grammarPoints.indexOf(window.location.pathname.split('/').pop());
        const targetIndex = currentIndex + direction;
        
        if (targetIndex >= 0 && targetIndex < grammarPoints.length) {
            navigate(`/grammar/${grammarPoints[targetIndex]}`);
        }
    };
    
    return (
        <div className='button-container'>
            <button className={isBackButtonDisabled ? 'disabledButton' : ''} disabled={isBackButtonDisabled} onClick={() => navigateGrammar(-1)} >戻る</button>
            <button onClick={() => navigate('/grammar/list')}>一覧</button>
            <button className={isNextButtonDisabled ? 'disabledButton' : ''} disabled={isNextButtonDisabled} onClick={() => navigateGrammar(1)}>次へ</button>
        </div>
    );
}

export default ListButton;



