import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Wotosite() {

    const grammarData={
        "pageTitle": "～を通して",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Thông qua…/ Trong suốt…/ Trải qua… ", "description": " ( ～を介して/～を間において/～を手段として…する/～を経験して…する)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["を通して(をとおして)"],
                "content": [
                    {"text": "N 　+　を通して(をとおして)"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "①  Diễn tả: “ Thông qua, bằng phương tiện, trải qua…” để thực hiện một việc gì đó."},
                    {"text": "② “Trong suốt giai đoạn, quãng thời gian… “thì ."},
                    {"text": "  ≫ Cách dùng giống với　「通じて」, nhưng 「を通して」 còn được dùng khi người nói muốn truyền đạt ý nghĩa chủ động, tích cực thực hiện, hành động việc gì đó. Còn 「通じて」  dùng nhiều cho những diễn tả ngẫu nhiên, vô tình hoặc đơn thuần muốn miêu tả lại sự việc, và được dùng trong văn viết, tin tức, báo chí nhiều hơn."},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["を通して"],
                "examples": [
                    {"japanese":"失業保険の問い合わせについて、総務部を通してアポイントをお取りください。","translate":"Vui lòng thông qua phòng tổng vụ để đặt lịch hẹn trao đổi về bảo hiểm thất nghiệp."},
                    {"japanese":"日本での留学を通して、私は気配りの大切さを学んだ。","translate":"Qua việc du học tại Nhật, tôi đã học được tầm quan trọng của sự chu đáo."},
                    {"japanese":"日本企業での仕事を通して、時間守りの大切さを勉強できるようになりました。","translate":"Thông qua công việc tại xí nghiệp Nhật, tôi đã học được tầm quan trọng của việc tuân thủ thời gian."},
                    {"japanese":"インターネットを通して得た情報はすべて正しいとは限らない。","translate":"Thông tin có được thông qua internet không hẳn lúc nào cũng đúng."},
                    {"japanese":"この地方は一年を通して暑い。","translate":"Ở vùng này nóng suốt cả năm."},
                    {"japanese":"行方不明になった人を探すために、警察庁を通して現地と連絡を取った。","translate":"Để tìm kiếm người mất tích, chúng tôi đã liên lạc với địa phương qua cục cảnh sát."},
                    {"japanese":"日本の文化についての本を通して、日本のことに興味深くなりました。","translate":"Tôi đã trở nên quan tâm đến văn hóa Nhật nhờ việc đọc sách về văn hóa Nhật."},
                    {"japanese":"気象条件を通して、桜の開花の時期を予測することができます。","translate":"Chúng ta có thể dự đoán thời gian hoa anh đào nở thông qua điều kiện thời tiết."},
                    {"japanese":"契約書のトラブルについては、弁護士を通して交渉を行ってください。","translate":"Về vấn đề rắc rối trong hợp đồng, vui lòng tiến hành đàm phán qua luật sư."},
                    {"japanese":"スポーツを通して、日々の努力の大切さを学んだ。","translate":"Nhờ việc chơi thể thao, tôi đã hiểu được sự quan trọng của việc nỗ lực mỗi ngày."},
                    {"japanese":"介護の仕事を体験したのを通して、高齢者の悩み事がわかるようになりました。","translate":"Tôi đã hiểu được những vấn đề của người cao tuổi thông qua việc trải nghiệm công việc chăm sóc người già."}
                ]
                
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Wotosite;
