import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Deare() {

    const grammarData={
        "pageTitle": "～であれ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Cho dù…/ Dù có là… )", "description": "( たとえ～ても/~でも"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["N 　+　であれ"],
                "content": [
                    {"text": "N 　+　ならでは"},
                    {"text": "疑問詞(Từ nghi vấn)　+　であれ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả dù trong trường hợp, hoàn cảnh N thì sự việc ở sau cũng không thay đổi."},
                    {"text": "≫ Cách nói trang trọng, dùng nhiều trong văn viết. "}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["であれ"],
                "examples": [
                    {"japanese":"たとえ天気がどうであれ、私は行きます。","translate":"Dù thời tiết có thế nào đi nữa, tôi sẽ đi."},
                    {"japanese":"理由が何であれ、うそをつくのはよくない。","translate":"Bất kể lý do là gì thì nói dối cũng là không tốt."},
                    {"japanese":"たとえ大統領であれ、犯罪を犯せば罰を受ける。","translate":"Dù là tổng thống đi chăng nữa, nếu phạm tội thì sẽ bị trừng phạt."},
                    {"japanese":"どんな国であれ、教育を重視しない国は発展できないだろう。","translate":"Dù là quốc gia nào đi nữa, một quốc gia không chú trọng vào giáo dục thì khó mà phát triển được."},
                    {"japanese":"限りあるの資源なのだから、無料でもらえるものであれ、大切にしなければならない。","translate":"Vì là tài nguyên hữu hạn cho nên dù có được sử dụng miễn phí thì chúng ta cũng phải sử dụng một cách hợp lý."},
                    {"japanese":"彼女は相手が誰であれ、優しく扱う。","translate":"Cô ấy đối xử tốt với mọi người, bất kể người đó là ai."},
                    {"japanese":"英国ではたとえ女王であれ税金を払わなければならない。","translate":"Ở Vương quốc Anh, dù là nữ hoàng đi chăng nữa thì vẫn phải đóng thuế."},
                    {"japanese":"正当な理由であれ不当な理由であれ戦争を支持すべきではない。","translate":"Cho dù là lý do chính đáng hay không thì cũng không nên ủng hộ chiến tranh."},
                    {"japanese":"たとえ相手が上司であれ、悪いことをしたら、注意しなければならない。","translate":"Dù người đó là cấp trên đi chăng nữa, nếu họ làm điều gì đó sai thì vẫn phải nhắc nhở."},
                    {"japanese":"何をするのであれ、あきらめずにやり遂げるべきだ。","translate":"Dù bạn làm gì đi nữa, thì đừng bỏ cuộc hãy cố gắng hoàn thành đến cùng."}
                    ]
            }
        ]
    }

    return (
        <>
        <Detailpage grammarData={grammarData} />
        <Listbutton></Listbutton>
        </> 
    );
}

export default Deare;
