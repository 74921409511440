import React from 'react';
import './Detailpage.css';

function Detailpage({grammarData}) {

    return (
        <>
            <div className="page-title">
                <h1 >{grammarData.pageTitle}</h1>
            </div>

            <div className='main'>
                {grammarData.sections.map((section, index) => (
                    <div key={index} className="block">
                        <h4>{section.title}</h4>
                        {section.id===1?
                            section.content.map((contentItem, contentIndex)=>
                            <p className='highlight-red'><strong>{contentItem.text}</strong>{contentItem.description}</p>):''}

                        {section.id===2?
                            section.content.map((contentItem, contentIndex)=>
                            {
                                // Check wheather keywords is array, if not put it into an array
                                // Result: const keywords = ["ずにはすまない","ないではすまない"];
                                const keywords = Array.isArray(section.keyword) ? section.keyword : [section.keyword];

                                //Can not find parentheses () 
                                const escapedKeywords = keywords.map(keyword => keyword.replace(/[()]/g, '\\$&'));
                                 //"/(ずにはすまない|ないではすまない)/g"
                                const regex = new RegExp(`(${escapedKeywords.join('|')})`, 'g');

                                // const regex = new RegExp(`(${keywords.join('|')})`, 'g');

                                // Split the japanese text using the regex pattern
                                //  ['悪いことを犯したから罰を受け', 'ずにはすまない', '。']
                                const parts = contentItem.text.split(regex);

                                return(
                                    <p>
                                        {parts.map((part, partIndex) => !keywords.includes(part) ? 
                                        <span className='p' key={partIndex}><strong className='highlight-red'>{part}</strong></span>:
                                        <span key={partIndex} className={partIndex<2 & (part === "い" || part === "な" )?'strike-through':'highlight-blue'}>{part}</span>
                                )}
                                    </p>    
                                        )
                            })
                            :''}
                            
                        {section.id===3?
                            section.content.map((contentItem, contentIndex)=>
                            <p>{contentItem.text}</p>):''}
                        {section.id === 4 ?
                            <ol>
                                {section.examples.map((contentItem, contentIndex) => {
                                    // Check wheather keywords is array, if not put it into an array
                                    // Result: const keywords = ["ずにはすまない","ないではすまない"];
                                    const keywords = Array.isArray(section.keyword) ? section.keyword : [section.keyword];

                                    //"Result: /(ずにはすまない|ないではすまない)/g"
                                    const regex = new RegExp(`(${keywords.join('|')})`, 'g');

                                    // Split the japanese text using the regex pattern
                                    // Result: ['悪いことを犯したから罰を受け', 'ずにはすまない', '。']
                                    const parts = contentItem.japanese.split(regex);

                                    return (
                                        <li key={contentIndex}>
                                            {
                                                parts.map((part, partIndex) => keywords.includes(part) ? 
                                                <span key={partIndex} className='highlight-red'>{part}</span> :
                                                <span key={partIndex}>{part}</span>
                                            )}
                                            <br />→ {contentItem.translate}
                                        </li>
                                    );
                                })}
                            </ol>
                        : ''}
                    </div>
                ))}
            </div>

        </>
    );
}

export default Detailpage;
