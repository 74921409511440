import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Zunihasumanai() {

    const grammarData={
        "pageTitle": "～ずにはすまない・ないではすまない",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Không thể không…/ Không… thì không được", "description": "( ～しなければならない)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword": ["ずにはすまない・ないではすまない"],
                "content": [
                    {"text": "Vない + ずにはすまない・ないではすまない"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả cách nói khi sự cân nhắc,căn cứ theo nguyên tắc, quy tắc, đạo đức,… thì không thể không…, không còn cách nào khác mà phải thực hiện…"},
                    {"text": "≫ Văn cứng, dùng trong văn viết."},
                    {"text": "≫ Vế sau không đi với cách nói thể hiện ý chí, mệnh lệnh, phủ định. "}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword": ["ずにはすまない","ないではすまない","ないでは済まない"],
                "examples": [
                        {"japanese":"これだけのミスをしたら、店長に怒らずにはすまない。","translate": "Gây ra lỗi như vậy thì không thể tránh khỏi sự tức giận của quản lý."},
                        {"japanese":"どうせ返事せずにはすまないことなら、早く返事してしまったほうがいい。","translate": "Dù sao cũng phải trả lời nên tốt nhất là nên phản hồi lại sớm."},
                        {"japanese":"恩人の頼みなので、協力せずにはすまない。","translate": "Vì là nhờ vả của ân nhân nên không thể không hỗ trợ được."},
                        {"japanese":"悪いことを犯したから罰を受けずにはすまない。","translate": "Vì đã làm việc xấu nên không thể không nhận hình phạt."},
                        {"japanese":"ビザを更新したかったら、入国管理局に行かないではすまない。","translate": "Nếu muốn gia hạn visa, không thể không đến cục quản lý nhập cảnh được."},
                        {"japanese":"借りたブランド時計を壊してしまったので、弁償せずにはすまない。","translate": "Vì đã làm hỏng chiếc đồng hồ hiệu đã mượn nên không thể không bồi thường được."},
                        {"japanese":"一日中アルバイトをしたんけど、明日テストがありますので、眠くても勉強しないでは済まない。","translate": "Tôi đã làm việc thêm cả ngày, nhưng vì ngày mai có bài kiểm tra, nên dù buồn ngủ cỡ nào cũng không thể không học."},
                        {"japanese":"手抜き工事が原因でのビルの倒壊事故なので、建設会社が責任を持たずにはすまない。","translate": "Vì vụ tai nạn sập tòa nhà nguyên nhân do xây dựng ẩu, nên công ty xây dựng không thể tránh khỏi việc phải nhận trách nhiệm."},
                        {"japanese":"迷惑をかけてしまったんだから、お詫びせずにはすまない。","translate": "Vì đã gây phiền phức, nên không thể không xin lỗi được."},
                        {"japanese":"取引先からの電話なので、出ずにはすまない。","translate": "Đây là cuộc gọi từ đối tác kinh doanh, không thể không nhấc máy được."},
                        {"japanese":"先輩の指導のおかげで仕事に慣れたのでお礼を言わないではすまない。","translate": "Nhờ sự hướng dẫn của tiền bối nên tôi đã quen với công việc nên không thể không cảm ơn được."}
                ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </>
    );
}

export default Zunihasumanai;
