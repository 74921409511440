import React, { useMemo, useEffect } from "react";
import { useTable } from "react-table";
import "./Homepage.css";
import { useNavigate } from 'react-router-dom';

function Homepage() {

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);

     // Define columns for the table
    const columns = useMemo(
        () => [
        { Header: "ID", accessor: "id" },
        { Header: "Ngữ Pháp", accessor: "grammar" },
        { Header: "Nghĩa", accessor: "mean" }
        ],
        []
    );

    // Define data for the table
    const data = useMemo(() => [
        {id: 1, navigate:'uede', grammar: '～うえで', mean: 'Sau khi… ( ～してから/~したという状況で…)'},
        {id: 2, navigate:'zunihasumanai', grammar: '～ずにはすまない・ないではすまない', mean: 'Không thể không…/ Không… thì không được ( ～しなければならない)'},
        {id: 3, navigate:'kotonasini', grammar: '～ことなしに', mean: 'Không thể không…/ Không… thì không được ( ～しなければならない)'},
        {id: 4, navigate:'dearedeare', grammar: '～であれ～であれ', mean: 'Cho dù… hay…  ( ～N1でもN2でも、どちらの場合であっても)'},
        {id: 5, navigate:'karekare', grammar: '～かれ～かれ', mean: 'Dù… Hay…   ( ～N1でもN2でも、どちらの場合であっても)'},
        {id: 6, navigate:'deare', grammar: '～であれ', mean: 'Cho dù…/ Dù có là…  ( たとえ～ても/~でも)'},
        {id: 7, navigate:'naradeha', grammar: '～ならでは・ならではの', mean: 'Chỉ có thể là…/Chỉ có…mới… ( ～でなければ…できない/~だけに特有の…)'},
        {id: 8, navigate:'wotosite', grammar: '～を通して', mean: 'Thông qua…/ Trong suốt…/ Trải qua… ( ～を介して/～を間において/～を手段として…する/～を経験して…する)'},
        {id: 9, navigate:'toareba', grammar: '～とあれば', mean: 'Nếu… thì …( ～なら…/～なのだから…)'},
        {id: 10, navigate:'tomonaruto', grammar: '～ともなると・ともなれば', mean: 'Cứ hễ…, Một khi đã … ( ～Aという高い立場・状況・程度・時になると当然Bという状況になる)'},
        {id: 11, navigate:'bakoso', grammar: '～ばこそ', mean: 'Chính vì… ( 他のことではない～という理由で)'},
        {id: 12, navigate:'saini', grammar: '～際［に・は］', mean: 'Khi / Trong(trường hợp)… ( ～の時［に・は］)'},
        {id: 13, navigate:'naidemonai', grammar: '～ない(もの)でもない', mean: 'Không phải là không…/Không hẳn là không… ( まったく～ないのではない/～の可能性がある)'},
        {id: 14, navigate:'narini', grammar: '～なりに・なりの', mean: 'Theo cách của… / Tương ứng với…/  Trong khả năng.../ Ở lập trường của…/ ( ～のレベル・立場・条件に合った程度で［の］)'},
        {id: 15, navigate:'tohaie', grammar: '～とはいえ', mean: 'Tuy/Dù… nhưng…( ～けれども/～といっても)'},
        {id: 16, navigate:'muki', grammar: '～向き', mean: 'Phù hợp với…/…Thích hợp với…( ～にちょうど合う）'},
        {id: 17, navigate:'muke', grammar: '～向け', mean: 'Dành cho…/ Hướng đến…( ～が使うために…された/～のために）'},
        {id: 18, navigate:'mademonai', grammar: '～までもない', mean: 'Không cần thiết phải… ( ～しなくてもいい/わざわざ～する必要がない）'},
        {id: 19, navigate:'tekaratoiumono', grammar: '～てからというもの', mean: 'Kể từ sau khi…( してからずっと/～て以来）'},
        {id: 20, navigate:'zukume', grammar: '～ずくめ', mean: 'Toàn là/Chỉ toàn là ( ～）'},
        {id: 21, navigate:'atteno', grammar: '～あっての', mean: 'B Tồn tại được là nhờ A/ B Có được là nhờ A (AがあるからBが成り立つ）'},
        {id: 22, navigate:'ageku', grammar: '～あげく', mean: 'Sau khi… thì/Sau khi đã… thì/ Sau khi… cuối cùng thì… ( いろいろ～した後で、最後は残念な結果になった）'},
        {id: 23, navigate:'yonimonai', grammar: 'Vよう + にも…ない', mean: 'Dù có muốn cũng không được…/Dù có muốn cũng không thể ( しようと思っても、事情があってできない）'}
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
      } = useTable({ columns, data });

    // Add a new function to handle row clicks
    const handleRowClick = (row) => {
        // Redirect to another page, with state or pathname that includes the grammar
        navigate(`/grammar/${row.original.navigate}`); 
    };

    return (
    <>
        <div class="page-title">
            <h1 >Ngữ Pháp Tiếng Nhật Trung Cao Cấp</h1>
        </div>
        
        <div className="table-container">
            <table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps({
                        onClick: () => handleRowClick(row), // Handle click event
                        className: 'tableRow',
                    })}>
                    {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                    </tr>
                );
                })}
            </tbody>
            </table>
        </div>
        </>
    );
}
export default Homepage;
