import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Mademonai() {

    const grammarData={
        "pageTitle": "～てからというもの",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Kể từ sau khi…", "description": "( してからずっと/～て以来）"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["からというもの"],
                "content": [
                    {"text": "Vて +　からというもの"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: tình trạng từ sau khi làm một việc gì đó thì có trạng thái nào đó cứ tiếp diễn, kéo dài mãi."},
                    {"text": "≫ Nghĩa tương tự: 「て以来」「Vてからは」．"},
                    {"text": "※ Cấu trúc này dùng để nhấn mạnh những điều xảy ra kể từ khi làm điều gì đó nên không được dùng cho mốc quá khứ gần."},


                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["てからというもの","からというもの"],
                "examples": 
                [
                    {"japanese":"彼女は恋人と別れてからというもの、勉強が全く手につかないらしい。","translate":"Sau khi chia tay bạn trai, có vẻ cô ấy hoàn toàn không thể tập trung vào việc học được."},
                    {"japanese":"タバコをやめてからというもの、体の調子がとてもいい。","translate":"Sau khi bỏ thuốc lá, tình trạng sức khỏe của cơ thể rất tốt."},
                    {"japanese":"90代に入ってからというもの、ネットの普及に凄まじいものがある。","translate":"Sau khi bước sang thập niên 90, sự phổ biến của internet trở nên rất đáng kinh ngạc."},
                    {"japanese":"新しい家を買ってからというもの、旅行どころか外食もしていない。","translate":"Sau khi mua căn nhà mới, đừng nói là đi du lịch tôi còn không ăn ngoài."},
                    {"japanese":"結婚してからというもの、父は母に一度も「ありがとう」と言っていない。","translate":"Sau khi kết hôn, cha tôi chưa từng nói 'cảm ơn' với mẹ một lần nào."},
                    {"japanese":"日本へ留学に行ってからというもの、親のことを思わない日はない。","translate":"Sau khi đi du học sang Nhật Bản, không có một ngày nào là tôi không nghĩ đến bố mẹ."},
                    {"japanese":"田中さんは社長にみんなの前でものすごく叱られた。それからというもの彼は別人になったかのように性格が変わった。","translate":"Anh Tanaka đã bị chủ tịch mắng nhiếc một cách thậm tệ trước mặt mọi người. Kể từ đó, tính cách của anh ấy đã thay đổi như một người khác."},
                    {"japanese":"会社が倒産してからというもの、父は前より一層口が重くなりました。","translate":"Sau khi công ty phá sản, bố tôi trở nên kiệm lời hơn trước rất nhiều."},
                    {"japanese":"この会社に入ってからというもの、日付が変わる前に帰ったことがない。","translate":"Kể từ khi gia nhập công ty này, tôi chưa bao giờ về nhà trước 12h đêm."}
                ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Mademonai;
