import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Toareba() {

    const grammarData={
        "pageTitle": "～とあれば",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Nếu… thì …", "description": "( ～なら…/～なのだから…)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["とあれば"],
                "content": [
                    {"text": "V普通・Aい　　+　　とあれば"},
                    {"text": "N ・Aな 　+　　とあれば"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: “ Nếu với trường hợp, tình huống, điều kiện đặc biệt nào đó”… sẽ dẫn tới có hành động, ý chí, nguyện vọng, phán đoán ở vế sau."},
                    {"text": "≫ Nghĩa tương đương với 「なら」. Dùng cho cả văn viết và văn nói."}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["とあれば"],
                "examples": [
                    {"japanese":"彼は恋人のためとあれば、どんなに仕事が忙しくても必ず駆けつける。","translate":"Nếu là vì bạn gái thì dù có bận đến đâu anh ấy cũng sẽ chạy tới ngay."},
                    {"japanese":"恩人の頼みとあれば、協力しないわけにはいかない。","translate":"Nếu là sự nhờ vả từ ân nhân, thì không thể không giúp sức."},
                    {"japanese":"子供のためとあれば、どんなこともする。","translate":"Nếu vì con cái, thì tôi sẵn lòng làm mọi thứ."},
                    {"japanese":"必要とあれば、弁護士と相談に行きます。","translate":"Nếu cần thiết, tôi sẽ đi thảo luận với luật sư."},
                    {"japanese":"彼はお金のためとあれば、手段を選ばずに何でもする。","translate":"Vì tiền bạc, anh ta sẽ làm bất cứ điều gì không từ thủ đoạn."},
                    {"japanese":"仕事とあればどこへでも行きます。","translate":"Nếu là công việc, thì dù ở đâu cũng đi."},
                    {"japanese":"少しでも役に立つとあれば、いかなる援助も致しましょう。","translate":"Nếu có giúp ích được dù chỉ là một chút thôi , tôi sẽ hỗ trợ mọi cách có thể."},
                    {"japanese":"安く買えるとあれば、遠くでも行きます。","translate":"Nếu có thể mua giá rẻ, dù có đi xa cũng được."},
                    {"japanese":"体が弱いだけでなく、太っているとあれば、なおさら食習慣に気を付けるべきだ。","translate":"Không chỉ có sức khỏe yếu, mà còn mập nữa thì càng phải chú ý hơn đến thói quen ăn uống."},
                    {"japanese":"課長の承認が得られないとあれば、この計画を実行に移せないだろう。","translate":"Nếu không có sự phê duyệt từ trưởng phòng, có lẽ kế hoạch này không thể thực hiện được."},
                    {"japanese":"人気がある店で美味しいケーキが食べるとあれば、行列に並んでも買いに行く。","translate":"Nếu có thể thưởng thức một chiếc bánh ngon từ cửa hàng nổi tiếng, dù có xếp hàng đợi tôi vẫn sẽ đi mua."}
                ]
                
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Toareba;
