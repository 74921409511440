import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './Components/Grammar/Homepage/Homepage';

import Uede from './Components/Grammar/Upperintermediate/Uede';
import Zunihasumanai from './Components/Grammar/Upperintermediate/Zunihasumanai';
import Kotonasini from './Components/Grammar/Upperintermediate/Kotonasini';
import Dearedeare from './Components/Grammar/Upperintermediate/Dearedeare';
import Karekare from './Components/Grammar/Upperintermediate/Karekare';
import Deare from './Components/Grammar/Upperintermediate/Deare';
import Naradeha from './Components/Grammar/Upperintermediate/Naradeha';
import Wotosite from './Components/Grammar/Upperintermediate/Wotosite';
import Toareba from './Components/Grammar/Upperintermediate/Toareba';
import Tomonaruto from './Components/Grammar/Upperintermediate/Tomonaruto';
import Bakoso from './Components/Grammar/Upperintermediate/Bakoso';

import Saini from './Components/Grammar/Upperintermediate/Saini';
import Naidemonai from './Components/Grammar/Upperintermediate/Naidemonai';
import Narini from './Components/Grammar/Upperintermediate/Narini';
import Tohaie from './Components/Grammar/Upperintermediate/Tohaie';
import Muki from './Components/Grammar/Upperintermediate/Muki';
import Muke from './Components/Grammar/Upperintermediate/Muke';
import Mademonai from './Components/Grammar/Upperintermediate/Mademonai';
import Tekaratoiumono from './Components/Grammar/Upperintermediate/Tekaratoiumono';
import Zukume from './Components/Grammar/Upperintermediate/Zukume';
import Atteno from './Components/Grammar/Upperintermediate/Atteno';
import Ageku from './Components/Grammar/Upperintermediate/Ageku';
import Yonimonai from './Components/Grammar/Upperintermediate/Yonimonai';
import Homebody from './Components/Homebody/Homebody';

function App() {
  return (
    <Router>
      <Routes>
        {/* 中上級レベル */}
        <Route path="/grammar/list" element={<Homepage />} />
        
        <Route path="/grammar/uede" element={<Uede />} />
        <Route path="/grammar/zunihasumanai" element={<Zunihasumanai />} />
        <Route path="/grammar/kotonasini" element={<Kotonasini />} />
        <Route path="/grammar/dearedeare" element={<Dearedeare />} />
        <Route path="/grammar/karekare" element={<Karekare />} />
        <Route path="/grammar/deare" element={<Deare />} />
        <Route path="/grammar/naradeha" element={<Naradeha />} />
        <Route path="/grammar/wotosite" element={<Wotosite />} />
        <Route path="/grammar/toareba" element={<Toareba />} />
        <Route path="/grammar/tomonaruto" element={<Tomonaruto />} />
        <Route path="/grammar/bakoso" element={<Bakoso />} />

        <Route path="/grammar/saini" element={<Saini />} />
        <Route path="/grammar/naidemonai" element={<Naidemonai />} />
        <Route path="/grammar/narini" element={<Narini />} />
        <Route path="/grammar/tohaie" element={<Tohaie />} />
        <Route path="/grammar/muki" element={<Muki />} />
        <Route path="/grammar/muke" element={<Muke />} />
        <Route path="/grammar/mademonai" element={<Mademonai />} />
        <Route path="/grammar/tekaratoiumono" element={<Tekaratoiumono />} />
        <Route path="/grammar/zukume" element={<Zukume />} />
        <Route path="/grammar/atteno" element={<Atteno />} />
        <Route path="/grammar/ageku" element={<Ageku />} />
        <Route path="/grammar/yonimonai" element={<Yonimonai />} />
        
        <Route path="" element={<Homebody />} />
        {/* other routes... */}
      </Routes>
  </Router>
  );
}

export default App;
