import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Naidemonai() {

    const grammarData={
        "pageTitle": "～ない(もの)でもない",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Không phải là không…/Không hẳn là không… ", "description": "( まったく～ないのではない/～の可能性がある)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["ない(もの)でもない","(もの)でもない"],
                "content": [
                    {"text": "Vない・Aいく・Aな　　+　　ない(もの)でもない"},
                    {"text": "N が 　　+　　ない(もの)でもない"}

                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả sự phủ định rằng không hẳn là không… thể hiện chuyện như vậy không phải là hoàn toàn không…Mà nếu có đủ điều kiện thì vẫn có khả năng xảy ra. "},
                    {"text": "≫ Cách nói mô tả suy nghĩ, nhận xét, cảm nhận mang tính cá nhân. "},
                    {"text": "≫ Ngoài ra còn có cách thể hiện khác như:「~ないこともない」　「～なくもない」"},
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ないものでもない","ないでもない","なくもない"],
                "examples": [
                    {"japanese":"急いで頑張れば、明日までに完成しないものでもない。","translate":"Nếu cố gắng làm nhanh thì không hẳn là không thể hoàn thành được vào ngày mai."},
                    {"japanese":"発売されたばかりのスマホはかなり高いが、買えないものでもない。","translate":"Mặc dù điện thoại mới ra mắt khá đắt nhưng không hẳn là không thể mua được."},
                    {"japanese":"運動したいという気持ちがないでもないのだが、なかなか踏み出せない。","translate":"Không hẳn là không muốn vận động nhưng mãi mà chưa thực hiện được."},
                    {"japanese":"この程度の簡単な料理なら、腕が悪い私でもできないでもない。","translate":"Với một món ăn đơn giản như thế này thì với người nấu ăn dở như tôi cũng có thể làm được."},
                    {"japanese":"ベジタリアンではないので肉を食べないでもないのですが、あまり好きではありません。","translate":"Vì không phải là người ăn thuần chay nên không hẳn là tôi không ăn được thịt, chỉ là không thích lắm thôi."},
                    {"japanese":"ギターを弾くのは好きだが、ギターのレッスンをやめようと思うことがなくもない。","translate":"Tôi thích đánh gita nhưng thú thật thì cũng có lần tôi nghĩ sẽ từ bỏ việc luyện tập đánh gita rồi."},
                    {"japanese":"お酒を飲まないでもないんですが、あまり好きではない。","translate":"Không hẳn là không uống được rượu, chỉ là không thích mà thôi."},
                    {"japanese":"相手がちゃんと反省したら、許してあげないでもないんだけど。","translate":"Nếu người đó thực sự nhận ra lỗi lầm thì không hẳn là tôi sẽ không tha thứ."},
                    {"japanese":"彼のほうにも責任がないものでもない。","translate":"Phía anh ta cũng có phần trách nhiệm."},
                    {"japanese":"彼が悪いと思わないでもないがあなたにも少し日があると思うよ。","translate":"Không phải là tôi không thấy anh ta làm sai, nhưng từ phía cậu cũng có chút lỗi đấy."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Naidemonai;
