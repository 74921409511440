import Detailpage from '../Detailpage/Detailpage';
import Listbutton from '../Directionbutton/Directionbutton';

function Zukume() {

    const grammarData={
        "pageTitle": "～ずくめ",
        "sections": [
            {
                "id": 1,
                "title": "Ý NGHĨA:",
                "content": [
                    {"text": "Toàn là.../ Chỉ toàn là... ", "description": "( ～ばかりある/　出来ことが続いて起こる)"}
                ]
            },
            {
                "id": 2,
                "title": "CẤU TRÚC:",
                "keyword":["ずくめ"],
                "content": [
                    {"text": "N +　ずくめ"}
                ]
            },
            {
                "id": 3,
                "title": "CÁCH DÙNG:",
                "content": [
                    {"text": "≫ Diễn tả: Những việc xảy ra xung quanh toàn là như thế. Nhìn vào đâu cũng chỉ có sự việc đặc biệt đó. "},
                    {"text": "≫ Trạng thái mà từ đầu đến cuối nhất quán một trạng thái, cùng một sự việc, sự vật. "},
                    {"text": "≫ Ví dụ tiêu biểu thường gặp: 「いいこと～」toàn là điều tốt,「色（黒…）～服装」trang phục toàn màu đen."},
                    {"text": "≫ Cách diễn đạt tập trung về số lượng(数) hơn là lượng（量）."},
                    {"text": "≫ Không sử dụng cho người và chất lỏng."},
                    {"text": "※　Cách nói này người nhật khá ít khi sử dụng, nhưng JLPT N1,N2 dễ bắt gặp."},
                    {"text": "≫ Phân biệt ずくめ・まみれ・だらけ・ぐるみ"}
                ]
            },
            {
                "id": 4,
                "title": "VÍ DỤ:",
                "keyword":["ずくめ"],
                "examples": [
                    {"japanese":"今日は父の誕生日なので、ご馳走ずくめの食事だった。","translate":"Hôm nay là sinh nhật của cha tôi, vì vậy bữa ăn toàn là món ngon."},
                    {"japanese":"頭の天辺からつま先までブランドずくめの服装だね。","translate":"Từ đầu đến chân, toàn bộ trang phục đều là nhãn hiệu nổi tiếng nhỉ."},
                    {"japanese":"その政策は国民にとっていいことずくめだ。","translate":"Chính sách đó mang lại nhiều lợi ích cho người dân."},
                    {"japanese":"運動もしないで、食べるだけでやせられるなんて、いいことずくめの健康法だ。","translate":"Không cần vận động, chỉ cần ăn cũng giảm cân được đúng là phương pháp chăm sóc sức khỏe tốt quá nhỉ (nói châm chọc)."},
                    {"japanese":"あのアルバイトは時給が高いし、店長が優しいし、いいことずくめなので、人気がある。","translate":"Công việc làm thêm đó được yêu thích vì toàn những điều tốt như là mức lương theo giờ cao, người quản lý tốt bụng."},
                    {"japanese":"目撃者によりますと、犯人は黒ずくめの服装ということです。","translate":"Theo lời nhân chứng, thủ phạm mặc trang phục toàn màu đen."},
                    {"japanese":"今年の冬は30℃を超えた日もあったし、短いし異例ずくめであった。","translate":"Mùa đông năm nay có những ngày trên 30 độ, lại rất ngắn, toàn những điều khác thường."},
                    {"japanese":"彼から手紙が来たし、おばさんからお小遣いをもらったし、今日は今朝からいいことずくめだ。","translate":"Sáng hôm nay nào là nhận được thư từ người yêu, nào là được cô cho tiền tiêu vặt, toàn là điều vui."},
                    {"japanese":"今年は娘の結婚、気に入ってる車が手に入り、めでたいことずくめの一年だった。","translate":"Năm nay đã trải qua nhiều điều tốt lành, nào là đám cưới của con gái, mua một chiếc xe yêu thích và nhiều điều vui vẻ khác."}
                    ]
            }
        ]
    }

    return (
        <>
            <Detailpage grammarData={grammarData} />
            <Listbutton></Listbutton>
        </> 
    );
}

export default Zukume;
